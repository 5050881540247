import { strapi } from './strapi'

export let infoBar = {
    rowHeight: 90,
    initialYOffset: 0,
    rowsYOffset: 0,
    rows: [
        "One morning, when **Gregor Samsa** woke from troubled dreams, he found",
        "himself transformed in his bed into a **horrible vermin**. He lay on his",
        "armour-like back, and if he lifted his head a little he could see",
        "his brown belly, slightly **domed** and **divided** by arches into stiff sections.",
    ],
    currentRows: [],
    rowsEl: undefined,
    totalSteps: 0,
    advanceTimeout: 7000,
    advanceInterval: -1,
    currentInforollNumber: 0,
    freezeInforoll: false,
    frozenInforollText: '',

    initVariables: () => {
        infoBar.initialYOffset = -infoBar.rowHeight;
        infoBar.rowsYOffset = infoBar.initialYOffset;
        infoBar.rowsEl = document.getElementById("infobar-rows");
        infoBar.totalSteps = infoBar.rowsEl.children.length + 2;
    },

    createDomElements: () => {
        let rowParent = document.getElementById("infobar-rows");
        rowParent.innerHTML = "";
        if (infoBar.freezeInforoll)
            infoBar.rows = [[infoBar.frozenInforollText]];
        else
            infoBar.rows = infoBar.getRows();
        
        infoBar.currentRows = infoBar.rows[infoBar.currentInforollNumber];
        infoBar.currentRows?.forEach(row => {
            var rowNode = document.createElement("div");
            rowNode.className = 'infobar-row bar-height';
            const rowText = row.replaceAll(/[*][*](.*?)[*][*]/g, '<span class="hl">$1</span>');
            rowNode.innerHTML = rowText;
            rowParent.appendChild(rowNode);
        });
    },

    getRows: () => {
        infoBar.rows = strapi.getInforollRows()?.split("\n\n").map(inforoll => inforoll.split("\n"));
        return infoBar.rows;
    },

    start: () => {
        infoBar.freezeInforoll = strapi.getFreezeInforoll();
        infoBar.frozenInforollText = strapi.getFrozenInforollText();

        infoBar.createDomElements();
        infoBar.initVariables();
        if (!infoBar.freezeInforoll)
            infoBar.advanceInterval = setInterval(infoBar.advanceNextRow, infoBar.advanceTimeout);
        infoBar.advanceNextRow();
    },

    advanceNextRow: () => {
        anime({
            targets: infoBar.rowsEl,
            translateY: infoBar.rowsYOffset,
            easing: 'easeInOutQuad',
            duration: 250,
        });
        infoBar.rowsYOffset -= infoBar.rowHeight;
        if (infoBar.freezeInforoll)
            return;

        if (infoBar.rowsYOffset <= -infoBar.rowHeight * (infoBar.totalSteps)) {
            infoBar.currentInforollNumber++;
            if (infoBar.currentInforollNumber >= infoBar.rows.length) {
                infoBar.currentInforollNumber = 0;
            }
            // console.log("Advancing inforoll", infoBar.currentInforollNumber);
            setTimeout(infoBar.clearIntervalAndReset, 250);
        }
    },

    clearIntervalAndReset: () => {
        clearInterval(infoBar.advanceInterval);
        anime({
            targets: infoBar.rowsEl,
            translateY: 0,
            easing: 'steps(1)'
        });
        if (window.stopLoop)
            return;
        
        oengusRenderer.showDonationGoalProgressBar();
    }
}

window.infoBar = infoBar;